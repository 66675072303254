<template>
  <div class="popup-modal" v-if="showPopup">
    <div class="popup-content">
      <span class="popup-close" @click="closePopup">&times;</span>
      <div class="login_logo">
        <img 
          src="/ssr/img/tiktok_voice_logo.svg" 
          alt="TikTok AI Voice Generator logo" 
          loading="lazy" 
          height="60px" 
          width="80px" 
          class="top_left_logo"
        />
      </div>
      <h2 class="popup-modal-title">{{ $t('txt2voice.popup_download_title') }}</h2>
      <ul class="feature-list">
        <li>⬇️ {{ $t('txt2voice.popup_modal_features.unlimited') }}</li>
        <li>📣 {{ $t('txt2voice.popup_modal_features.voices') }}</li>
        <li>🎉 {{ $t('txt2voice.popup_modal_features.api') }}</li>
      </ul>
      <a :href="pricing_href" class="popup-btn">
        {{ $t('txt2voice.popup_download_btn') }}
        <span class="popup-btn-badge">{{ $t('txt2voice.popup_download_discount') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupDownload',
  data() {
    return {
      showPopup: false,
      pricing_href: '/en/pricing'
    };
  },
  methods: {
    openPopup() {
      this.showPopup = true;
    },
    closePopup() {
      this.showPopup = false;
      this.$emit('close');
    },
    updateLinks(lang) {
      this.pricing_href = `/${lang}/pricing`
    },
  },
  mounted () {
    this.updateLinks(this.$store.state.lang)
  }
};
</script>