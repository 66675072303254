<template>
  <div class="popup-modal" v-if="visible">
    <div class="popup-content">
      <span class="popup-close" @click="close">&times;</span>
      <div class="login_logo">
        <img src="/ssr/img/tiktok_voice_logo.svg" alt="TikTok AI Voice Generator logo" loading="lazy" height="60px" width="80px" class="top_left_logo"/>
      </div>
      <h2 class="popup-modal-title">{{ data.title }}</h2>
      <ul class="feature-list">
        <li>🎭 {{ $t('txt2voice.popup_modal_features.voices') }}</li>
        <li>🤖 {{ $t('txt2voice.popup_modal_features.api') }}</li>
        <li>⬇️ {{ $t('txt2voice.popup_modal_features.unlimited') }}</li>
      </ul>
      <a :href="pricing_href" class="popup-btn">
        {{ data.buttonText }}
        <span class="popup-btn-badge">{{ $t('txt2voice.popup_download_discount') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupModal',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      visible: true,
      pricing_href: '/en/pricing'
    }
  },
  methods: {
    close() {
      this.visible = false;
      this.$emit('close');
    },
    updateLinks(lang) {
      this.pricing_href = `/${lang}/pricing`
    }
  },
  mounted () {
    this.updateLinks(this.$store.state.lang)
  }
}
</script>

<style scoped>

</style>
