<template>
  <footer>
    <div class="footer_div">
      <div class="footer_div_last">
        <a href="/en/privacy-policy" title="TikTok Voice privacy policy" class="footer-link-last">{{ $t('comm.privacy_policy') }}</a>
        <a href="/en/terms-of-service" title="TikTok Voice term of service"  class="footer-link-last">{{ $t('comm.terms_conditions') }}</a>
        <a href="/en/about" class="footer-link-last">{{ $t('comm.about_us') }}</a>
        <span class="footer-link-last">{{ $t('comm.footer_help') }} tiktokaivoicetool@gmail.com</span>
      </div>
    </div>

    <div class="copyright">
      <span>&copy; {{ currentYear }} TikTokVoice.net All rights reserved.</span>
    </div>

    <div class="friend-links">
      <a v-for="(link, index) in flattenedLinks"
         :key="index"
         :href="link.href"
         :title="link.text"
         target="_blank"
         rel="noopener noreferrer"
         class="friend-link">
        {{ link.text }}
      </a>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'VoiceFooter',
  data() {
    return {
      footerColumns: [
        [
          { href: "https://aivoicelab.net", text: "AIVoiceLabe" },
          { href: "https://bai.tools", text: "BAI.tools" },
          { href: "https://ai-findr.com/", text: "AI Findr Tools" },
          { href: "https://freeaitool.ai/", text: "Free AI Tool" },
          { href: "https://open.spotify.com/episode/6H3JHItVvQD20Sn0eRt4mu", text: "TikTok Voice Generator" },
          { href: "https://podcastindex.org/podcast/7113014", text:"TikTok tts"},
          { href: "https://www.perplexity.ai/page/tiktok-voice-generator-tool-pfbQJ1ePQWa4n0ko.iOPrQ", text: "text to speech tiktok"},
          { href: "https://www.similarweb.com/website/tiktokvoice.net/", text: "tiktok voice generator" },
          { href: "https://chatgpt.com/g/g-71XL5CDCa-text-to-speech-generator", text: "ChatGPT TikTok Voice Generator"},
        ],
        [
          { href: "https://seektop.ai", text: "SeekTop.ai" },
          { href: "https://allinai.tools", text: "All in AI" },
          { href: "https://aipure.ai/", text: "AIPURE AI" },
          { href: "https://aitoolcenter.com/", text: "AI Tool Center" },
          { href: "https://ttvoice.net/", text: "TikTok Voice Generator"},
          { href: "https://www.deezer.com/en/show/1001429561", text: "TikTok text to speech" },
          { href: "https://bit.ly/tiktok-voice-generator", text: "TikTok Voice Generator" },
          { href: "https://chrome-alley-19e.notion.site/3-Best-C3PO-Voice-Text-to-Speech-TTS-530c370932674611bda1b8c08c76d94d", text: "tiktok voice over generator"},
          { href: "https://www.producthunt.com/products/tiktok-voice", text: "tiktok tts" },
          { href: "https://chatgpt.com/g/g-uMg3fn6Zm-c3po-voice-generator", text: "ChatGPT TikTok Text to Speech"}
        ],
        [
          { href: "https://ainavigationsite.com/", text: "AI Navigation Site Tools " },
          { href: "https://aistage.net", text: "AIStage" },
          { href: "https://music.amazon.com/podcasts/720ae035-f543-42cb-8729-ab453f69a377/episodes/2e142d92-f970-4d14-8eb8-9e6ea737566c/two-interesting-ai-tools-tiktok-voice-generator", text: "TikTok tts" },
          { href: "https://www.pandora.com/podcast/2-interesting-ai-tools/PC:1001095302", text: "TikTok funny AI Voice"},
          { href: "https://podcasts.apple.com/us/podcast/two-interesting-ai-tools/id1781823656", text: "TikTok Voice Generator"},
          { href: "https://www.perplexity.ai/page/tiktok-voice-generator-explain-sXwR5QrVQ8uZw6k7LSNORA", text: "TikTok text to speech"},
          { href: "https://www.tumblr.com/wwwssslll/59738637048/the-tool-stolen-tiktok-voice?source=share", text: "TikTok AI Voice" },
          { href: "https://bit.ly/m/tiktok-text-to-speech", text: "TikTok tts" },
          { href: "https://chatgpt.com/g/g-qtCmQIoxy-ghostface-voice-generator", text: "ChatGPT TikTok AI Voice" }
        ]
      ]
    }
  },
  computed: {
    sendmail() {
      return `mailto:${this.$i18n.t('comm.footer_mail')}`
    },
    currentYear() {
      return new Date().getFullYear()
    },
    flattenedLinks() {
      return this.footerColumns.flat()
    }
  }
}
</script>
<style scoped>
.copyright {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.friend-links {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.friend-link {
  margin: 0 0.5rem;
  color: #fff;
  text-decoration: none;
}
</style>
