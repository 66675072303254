<template>
  <footer>
    <div class="footer_div">
      <div class="footer_div_last">
        <a href="/en/privacy-policy" title="TikTok Voice privacy policy"  class="footer-link-last">{{ $t('comm.privacy_policy') }}</a>
        <a href="/en/terms-of-service" title="TikTok Voice term of service"  class="footer-link-last">{{ $t('comm.terms_conditions') }}</a>
        <a href="/en/about" class="footer-link-last">{{ $t('comm.about_us') }}</a>
        <span class="footer-link-last">{{ $t('comm.footer_help') }} tiktokaivoicetool@gmail.com</span>
      </div>
    </div>
    
    <div class="copyright">
      <span>&copy; {{ currentYear }} TikTokVoice.net All rights reserved.</span>
    </div>
  </footer>
</template>
<script>
export default {
  name: 'VoiceFooter',

  computed: {
    sendmail() {
      return `mailto:${this.$i18n.t('comm.footer_mail')}`
    },
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>
<style scoped>
.copyright {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.5rem; 
}
</style>
